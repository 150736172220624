<template>
  <div class="roleAuth">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDioAuth">
        <!-- <el-tabs type="border-card" v-model="tabActive">
          <el-tab-pane name="1" label="角色授权菜单"> -->
            <div class="btnDiv mb10">
              <el-button type="primary" size="small" @click="checkAll()">一键全选</el-button>
            </div>
            <div class="treeDivAuth">
              <el-tree class="auth-tree" 
                :data="authData" 
                :props="defaultProps" 
                default-expand-all
                node-key="id"
                highlight-current
                ref="treeRef"
                :default-checked-keys="inform.menuIds"
                :expand-on-click-node="false"
                :show-checkbox="true"
                :check-strictly="false"
              ></el-tree>
            </div>
          <!-- </el-tab-pane>
          <el-tab-pane name="2" label="角色授权人员">
            <div class="transUser">
              <el-transfer
                v-model="inform.userIds"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="姓名搜索"
                :data="userData"
                :props="{key:'id',label:'account'}"
                :titles="['未选人员', '选中人员']"
              />
            </div>
          </el-tab-pane>
        </el-tabs> -->
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
        <el-button type="primary" @click="updateRole()" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
var vm;
export default {
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        roleName:'',//角色名称
        roleType:'',//角色类型
        roleCode:'',//角色编号
        appIds:[],//应用IDS
        menuIds:[],//菜单ids
        roleDescribe:'',//描述
      },

      tabActive :'1',
      authData :[],
      defaultId :[],//默认菜单选中
      userData :[],//人员数据
      
      defaultProps: {
        children: 'childPageMenus',
        label: 'menuName',
      },

    }
  },
  created(){
    vm = this
  },

  mounted(){
    
  },

  //方法
  methods:{
    //初始化
    async init (item){
      // console.log(item);
      // dioType.value = type
      vm.dioWidth = '60%'
      vm.isDio = true
      await vm.getPageMenuTreeByAppCode()
      vm.userListFun()
      vm.$nextTick(() => {
        vm.dioTit = '角色配置'
        vm.inform.id = item.id
        vm.inform.roleName=item.roleName//角色名称
        vm.inform.roleCode=item.roleCode//角色编号
        vm.inform.appIds=item.appIds||[]//应用ids
        // vm.inform.menuIds=item.menuIds||[]//菜单ids
        vm.inform.roleDescribe=item.roleDescribe//备注
        vm.$refs.treeRef.setCheckedKeys([])
        item.menuIds?.forEach(i => {
          // 根据id 拿到 Tree 组件中的node的所有信息
          let node = vm.$refs.treeRef.getNode(i);
          // node.isLeaf：判断当前节点是否为子节点
          // console.log(node)
          if (node.isLeaf) {
            //如果是子节点，就把状态设置成选中
            vm.$refs.treeRef.setChecked(node, true);
          } else {

          }
        })
      })
    },
    //一键全选
    checkAll(){
      this.$refs.treeRef.setCheckedNodes(this.authData)
    },
    //获取树形菜单
    async getPageMenuTreeByAppCode (){
      let dataObj = {
        'appCode': 'APP_SECURITY_PC',//应用编码
      }
      let res = await apiUrl.getPageMenuTreeByAppCode(qs.stringify(dataObj))
      if(res?.code == 200){
        vm.authData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取用户列表
    async userListFun (){
      const res = await apiUrl.userList({})
      if(res?.code==200){
        vm.userData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //人员过滤
    filterMethod (query, item){
      return item.account.includes(query)
    },
    //修改(菜单授权)
    async updateRole (){
      let dataObj = {
        'id':vm.inform.id,
        'roleName': vm.inform.roleName,//名称
        'roleCode': vm.inform.roleCode,//编码
        'appIds': vm.inform.appIds,//应用IDs
        'roleDescribe': vm.inform.roleDescribe,//描述
        'menuIds': vm.$refs.treeRef.getCheckedKeys().concat(vm.$refs.treeRef.getHalfCheckedKeys())||[],//选中的id数组
      }
      const res = await apiUrl.updateRole(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio()
      }else{
        vm.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio (){
      vm.inform.id=''
      vm.inform.roleName=''
      vm.inform.roleType=''
      vm.inform.roleCode=''
      vm.inform.roleCode=''
      vm.inform.appIds=[]
      vm.inform.menuIds=[]
      vm.inform.roleDescribe=''
      vm.isDio = false
    }
  }
}
</script>
<style lang='scss'>
.roleAuth{
  .contDioAuth{
    .treeDivAuth{
      padding: 10px;
      height: 530px;
      overflow: auto;
    }
    .transUser{
      text-align: center;
      margin: 50px auto;
    }
  }
}
</style>