<template>
  <div class="roleAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="角色名称:" prop="roleName">
            <el-input type="text" v-model="inform.roleName" placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="角色编号:" prop="roleCode">
            <el-input type="text" v-model="inform.roleCode" placeholder="请输入角色编号"></el-input>
          </el-form-item>
          <!-- <el-form-item label="应用:" prop="appIds">
            <el-checkbox-group v-model="inform.appIds">
              <el-checkbox v-for="(item,index) in appList" :key="index" :label="item.id">{{item.appName}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item> -->
          <!-- <el-form-item label="角色类型:" prop="roleType">普通用户</el-form-item> -->
          <el-form-item label="描述:" prop="roleDescribe">
            <el-input type="textarea" :rows="5" v-model="inform.roleDescribe" placeholder="请输入描述"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
var vm;
export default {
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        roleName:'',//角色名称
        roleType:'',//角色类型
        roleCode:'',//角色编号
        appIds:[],//应用IDS
        menuIds:[],//菜单ids
        roleDescribe:'',//描述
      },

      appList :[],
      
      cascProps :{
        value:'id',
        label:'depaName',
        children:'childDepartments',
        checkStrictly: true,
        emitPath:false,
      },

      rules :{
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
        roleCode: [
          { required: true, message: '请输入角色编号', trigger: 'blur' },
          { min:4, max:32, message: '只能输入4-32位角色编号', trigger: 'blur' },
        ],
        appIds: [
          { required: true, message: '请选择应用', trigger: 'change' },
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods:{
    //初始化
    async init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      await vm.getAllApp()
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '添加角色'
        }else if(type == 'edit'){
          vm.dioTit = '编辑角色'

          vm.inform.id = item.id
          vm.inform.roleName=item.roleName//角色名称
          vm.inform.roleCode=item.roleCode//角色编号
          vm.inform.appIds=item.appIds||[]//应用ids
          vm.inform.menuIds=item.menuIds||[]//菜单ids
          vm.inform.roleDescribe=item.roleDescribe//备注
        }
      })
    },
    //获取应用
    async getAllApp(){
      const res = await apiUrl.getAllApp()
      if(res?.code==200){
        vm.appList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addRoleInsert()
          }else if(vm.dioType=='edit'){
            vm.updateRole()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.inform.menuIds = []
      vm.isDio = false
    },
    
    //新增
    async addRoleInsert (){
      let dataObj = {
        'roleName': vm.inform.roleName,//名称
        'roleCode': vm.inform.roleCode,//编码
        'appIds': vm.inform.appIds,//应用IDs
        'roleDescribe': vm.inform.roleDescribe,//描述
      }
      const res = await apiUrl.addRoleInsert(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateRole (){
      let dataObj = {
        'id':vm.inform.id,
        'roleName': vm.inform.roleName,//名称
        'roleCode': vm.inform.roleCode,//编码
        'appIds': vm.inform.appIds,//应用IDs
        'roleDescribe': vm.inform.roleDescribe,//描述
        'menuIds': vm.inform.menuIds,//菜单IDS
      }
      const res = await apiUrl.updateRole(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
  }
}
</script>
<style lang='scss'>
.roleAddEdit{
  .contDio{
    .el-checkbox{
      color: #fff;
    }
  }
}
</style>